
@import "../../bootstrap/variables.less";

@body-bg:					#74b2f1;

@text-color:				#fff;
@link-color:				#0f4e1e;
@link-hover-color:			#478e58;
@link-hover-decoration:		none;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				400px;
@thumb-height:				300px;
@thumb-margin-top:			3px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #fff;
@thumb-hover-border:		@thumb-border-width dashed #478e58;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#74b2f1;
@popup-font-weight:			bold;

@social-links-margin:		30px 0 5px;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	font-size: 20px;
	color: #fff;
	text-align: center;
	margin-bottom: 30px;
	.title a {
		font-size: 50px;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
.trades {
	text-align: center;
	margin: 20px auto 3px;
	font-size: 13px;
	font-weight: bold;
}